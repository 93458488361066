@font-face {
  font-family: 'Lufga';
  src: url('./fonts/Lufga-Regular.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Lufga Md';
  src: url('./fonts/Lufga-Medium.otf') format('opentype');
  font-weight: 500;
  font-display: swap;
}

.general-layout {
  background: #201936;
  min-height: 100vh;
}

.page-wrapper {
  width: 90%;
  margin: auto;
  padding-top: 75px;
  color: #fff;
  font-family: Lufga;
}

.page-header {
  font-size: 28px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
  margin: 24px 0;

  @media (max-width: 576px) {
    font-size: 24px;
  }
}

.page-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;

  @media (max-width: 576px) {
    font-size: 14px;
  }

  b {
    margin: 16px 0;
  }
}