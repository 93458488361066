.navbar {
    top: 0;
    padding: 0;
    z-index: 9;

    .header-container {
        border-bottom: 2px solid rgba(255, 255, 255, 0.15);
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: auto;
        align-items: center;
        padding: 12px 0;
    }

    .menu-items {
        display: flex;

        @media (max-width: 576px) {
            display: none;
        }

        .menu-item {
            margin-left: 48px;
            font-family: Lufga;
            font-size: 20px;
            color: #fff;
            font-weight: 600;
            line-height: 130%;
            cursor: pointer;

            a {
                text-decoration: none;
                color: inherit;
            }
        }

        .active {
            color: #FAE83E !important;
        }
    }
}

.bg-general {
    background: #201936;
}