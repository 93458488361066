.home-wrapper {
    background-image: url('../../images/home-bg.webp');
    // opacity: 2;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.video-tag {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.center-banner-section {
    margin: auto;
    align-items: center;
    width: 90%;
    display: flex;

    .text-wrapper {

        .banner-title {
            color: #FFF;
            font-family: Lufga Md;
            font-size: 46px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            width: 90%;

            @media (max-width: 576px) {
                text-align: center;
                font-size: 24px;
                width: 100%;
            }
        }

        .banner-subtitle {
            color: #FFF;
            margin-top: 8px;
            font-family: Lufga;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            width: 90%;

            @media (max-width: 576px) {
                text-align: center;
                font-size: 16px;
                width: 100%;
            }
        }

        .banner-button {
            display: flex;
            padding: 12px 12px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            background: #FFF;
            width: 100%;
            border: none;
            margin-top: 20px;
            width: 85%;

            img {
                height: 38px;
            }

            @media (max-width: 576px) {
                width: 100%;

                img {
                    height: 30px;
                }
            }
        }
    }

    .img-wrapper {
        display: flex;
        justify-content: center;

        @media (max-width: 576px) {
            display: none;
        }
    }
}

.bottom-text {
    color:#FFF;
    text-align: center;
    font-family: Lufga;
    font-size: 16px;
    line-height: 140%;
    position: absolute;
    bottom: 50px;
}